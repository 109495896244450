<template>
  <a-drawer
    :title="`${viewModel ? '修改用户组' : '新建用户组'}`"
    :maskClosable="false"
    width="600"
    placement="right"
    :closable="true"
    @close="onClose"
    :visible="userGroupEditVisiable"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <a-form-model
      ref="ruleForm"
      :validateOnRuleChange="true"
      :model="userGroupForm"
      :rules="userGroupRules"
      :form="userGroupForm"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-model-item label="用户组名称" prop="groupName">
        <a-input
          placeholder="输入用户组名称"
          v-model="userGroupForm.groupName"
          style="width: 80%"
        />
      </a-form-model-item>

      <a-form-model-item label="组类型" prop="groupType">
        <a-select
          :default-value="1"
          v-model="userGroupForm.groupType"
          placeholder="选择用户组"
        >
          <a-select-option :value="1"> 社群群组 </a-select-option>
          <a-select-option :value="2"> 工程师群组 </a-select-option>
          <a-select-option :value="3"> 培训群组 </a-select-option>
          <a-select-option :value="4"> 一般群组 </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="描述" prop="description">
        <a-textarea
          v-model="userGroupForm.description"
          placeholder="通知消息内容"
          :rows="4"
        />
      </a-form-model-item>

      <a-form-model-item label="规则" prop="rules">
        <user-rule-creator
          @valueChange="rulesValueChange"
          ref="userRuleCreator"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-bootom-button">
      <a-button @click="onClose" style="margin-right: 0.8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading"
        >提交</a-button
      >
    </div>
  </a-drawer>
</template>
<script>
import UserRuleCreator from "../../business-common/UserRuleCreator";

const formItemLayout = {};
export default {
  name: "UserGroupEdit",
  components: { UserRuleCreator },
  data() {
    return {
      loading: false,
      formItemLayout,
      // deptTreeData: [],
      // roleData: [],
      viewModel: null,
      userGroupEditVisiable: false,
      userGroupForm: {
        groupId: undefined,
        groupName: undefined,
        rules: undefined,
        userIdList: undefined,
        groupType: undefined,
        createTime: undefined,
        updateTime: undefined,
        description: undefined,
      },
      userGroupRules: {
        groupName: [
          { required: true, message: "请填写用户组名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "用户组名称长度需在3到20之间",
            trigger: "blur",
          },
        ],
        rules: [{ required: true, message: "请指定规则", trigger: "blur" }],
        groupType: [{ required: true, message: "请选择类型" }],
      },
      backupData: null,
    };
  },
  mounted() {
    // this.$get("dept", { rootDeptIds: getDeptRootId() }).then((r) => {
    //   this.deptTreeData = r.data.rows.children;
    // });
    // this.$get("role").then((r) => {
    //   this.roleData = r.data.rows;
    // });
  },
  methods: {
    setForm(modelType, data) {
      this.viewModel = modelType;
      if (modelType) {
        for (let key in data) {
          this.userGroupForm[key] = data[key];
        }
        this.$nextTick(()=>{
          if (this.$refs.userRuleCreator)
            this.$refs.userRuleCreator.setRules(this.userGroupForm.rules);
        })
      } else {
        for (let key in this.userGroupForm) {
          this.userGroupForm[key] = undefined;
        }
        if (this.$refs.userRuleCreator)
          this.$refs.userRuleCreator.setRules(null);
      }

      this.backupData = JSON.parse(JSON.stringify(this.userGroupForm));
      this.userGroupEditVisiable = true;
    },
    rulesValueChange(s) {
      this.userGroupForm.rules = s;
    },
    reset() {
      if (this.backupData) {
        for (let key in this.backupData) {
          this.userGroupForm[key] = this.backupData[key];
        }
        this.$refs.userRuleCreator.setRules(null);
      }
    },
    onClose() {
      this.loading = false;
      this.userGroupEditVisiable = false;
      this.reset();
      this.$emit("close");
    },
    handleSubmit() {
      // console.log(this.userGroupForm.rules);
      // return;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let userGroup = this.userGroupForm;

          if (this.viewModel) {
            this.loading = true;
            this.$put("group", {
              ...userGroup,
            })
              .then((r) => {
                this.loading = false;
                this.$message.success("修改用户组成功");
                this.$emit("success");
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            this.loading = true;
            this.$post("group", {
              ...userGroup,
            })
              .then((r) => {
                this.loading = false;
                this.$message.success("新建用户组成功");
                this.$emit("success");
                this.reset();
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
  watch: {
    "userGroupForm.rules"() {},
  },
};
</script>
